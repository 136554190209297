.Error {
    width: 400px;
    margin-left: calc(50% - 200px);
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    padding: 30px;

    &__Code {
        text-align: center;
        color: $brand-primary;
        font-size: 3em;
        margin-bottom: 10px;
    }

    &__Message {
        text-align: center;
        font-size: 1.4em;
    }

    &__Controls {
        margin-top: 60px;
    }
}
