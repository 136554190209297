.Upcoming__Chats {
    padding: 10px;
    border-bottom: 1px solid #e4e4e4;

    &__Chat {
        border-bottom: 1px solid #e4e4e4;

        &:last-child {
            border-bottom: 0;
        }

        .Upcoming__Chats__Chat__Information {
            margin-top: 0 !important;
        }

        &--Has-Labels {
            .Upcoming__Chats__Chat__Information {
                margin-top: 10px !important;
            }
        }
    }

    &__Label {
        display: inline-block;
        border-radius: 6px;
        color: #fff;
        font-size: 0.857em;
        padding: 3px 10px;
        margin-right: 10px;

        &--Owner {
            background: $brand-primary !important;
        }

        &--Moderator {
            background: #3f51b5 !important;
        }

        &--Running {
            background: #8bc34a !important;
        }

        &--Scheduled {
            background: #62a316 !important;
        }

        &--Finished {
            background: #FF9100 !important;
        }

        &--Done {
            background: #D50000 !important;
        }

        &--Daily {
            background: #2196f3 !important;
        }

        &--Daily-Workdays {
            background: #21c1f2 !important;
        }

        &--Weekly {
            background: #2196f3 !important;
        }

        &--Monthly {
            background: #2196f3 !important;
        }
    }

    &__Timestamp {
        &--Start {

        }

        &--End {
            margin-left: 20px;
        }
    }
}
