.Login_Form {
    &-Forgotten {
        color: $vbst-intro-headline-color;
        font-weight: bold;
        font-size: 1em;

        &:active,
        &:hover {
            color: lighten($vbst-intro-headline-color, 20);
        }

        &:visited {
            color: $vbst-intro-headline-color;
        }
    }

    .Button {
        font-size: 1.143em;
        font-weight: bold;
        padding-top: 11px;
        padding-bottom: 11px;
    }

    .input-group {
        margin-bottom: 10px;
    }

    .form-control {
        font-size: 1.143em;
        padding: 11px 10px;
    }

    .input-group-btn .btn {
        height: 46px !important;
    }
}
