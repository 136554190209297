.dataTables_wrapper {
    margin-top: 20px;
    color: $datatables-text-color;

    .panel & {
        margin-top: 0;
    }

    .row {
        margin-left: 0;
        margin-right: 0;
        background: $datatables-row-bgcolor;

        &:first-child {
            border-top: 1px solid $datatables-border-color;
            border-bottom: 1px solid $datatables-border-color;
            background: $datatables-row-alternate-bgcolor;
        }

        &:nth-child(2) {
            padding: 0;

            .col-sm-12 {
                padding-left: 0;
                padding-right: 0;
            }
        }

        &:last-child {
            border-bottom-left-radius: $border-radius-base;
            border-bottom-right-radius: $border-radius-base;

            .col-sm-5,
            .col-sm-7 {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }
    }

    .dataTables_length,
    .dataTables_filter {
        padding: 20px 5px;
    }

    .dataTables_length {
        select {
            margin-left: 10px;
            margin-right: 10px;
            font-size: 1em;
        }
    }

    .dataTables_filter {
        label {
            position: relative;
            width: 300px;
        }

        input {
            position: absolute;
            left: 0;
            margin-left: 0 !important;
            width: 300px !important;
            font-size: 14px;
            box-shadow: rgba(0, 0, 0, 0.0470588) 0px 1px 1px inset;
            padding: 6px 8px;
            border-radius: 1px;
            height: 34px;
            line-height: 1.42857;
            color: rgb(85, 85, 85);
            background-color: rgb(255, 255, 255);
            border-width: 1px;
            border-style: solid;
            border-color: rgb(204, 204, 204);
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

            &:focus {
                border-color: #66afe9;
                outline: 0;
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
            }
        }
    }

    .dataTables_info,
    .dataTables_paginate {
        margin-top: -7px !important;
        padding: 0 5px 0;
    }

    .dataTables_info {
        padding-top: 15px !important;
        padding-left: 20px !important;
        font-style: italic;
        color: $datatables-text-alternate-color;
    }

    .dataTables_paginate {
        padding-right: 0 !important;
        height: 49px;
        overflow: hidden;
    }

    .pagination {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .pagination > .active > a,
    .pagination > .active > a:hover,
    .pagination > .active > a:focus,
    .pagination > .active > span,
    .pagination > .active > span:hover,
    .pagination > .active > span:focus {
        background-color: #fff;
        border: 1px solid $datatables-border-color;
        color: $brand-primary;
        font-weight: bold;
    }

    .pagination > li > a,
    .pagination > li > span {
        padding: 14px 24px;
    }

    .pagination > li:first-child > a,
    .pagination > li:first-child > span {
        border-radius: 0;
    }

    .pagination > li:last-child > a,
    .pagination > li:last-child > span {
        border-radius: 0;
        border-right: 0;
        border-bottom-right-radius: $border-radius-base;
    }

    .dataTables_processing {
        background: transparent;
       width: 30px !important;
       height: 43px;
       overflow: hidden;
       color: transparent;
       padding: 0 0 0 2px !important;
       top: 4px !important;
       left: auto !important;
       right: 15px;
       margin-left: 5px !important;
       margin-top: 0 !important;

        &:before {
            font-family: "Font Awesome 5 Pro"; 
            content: "\f013";
            margin-right: 14px;
            font-size: 2.2em;
            color: $datatables-processing-color;
            animation: processing-spin 1.5s infinite linear;
            display: inline-block;
        }
    }
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
    font-family: "Font Awesome 5 Pro" !important; 
    bottom: 14px !important;
    right: 12px !important;
    color: $datatables-text-color !important;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after {
    opacity: 1 !important;
}

table.dataTable thead .sorting:after {
    opacity: 0.5 !important;
}

table.dataTable thead .sorting:after {
    content: "\f0dc" !important;
}

table.dataTable thead .sorting_asc:after {
    content: "\f0de" !important;
}

table.dataTable thead .sorting_desc:after {
    content: "\f0dd" !important;
}

.DataTable {
    width: 100%;

    thead th,
    tbody td {
        border-top: 1px solid $datatables-border-color;
        padding: 9px 20px 15px;
    }

    thead > tr:first-child > th {
        border-top: 0;
    }

    tbody td {
        padding-top: 15px;
    }

    tbody tr:hover > td,
    tbody tr:nth-child(odd):hover > td {
        background: $datatables-row-hover-bgcolor;
    }

    tbody > tr > td {
        background: $datatables-row-bgcolor;
    }

    tbody > tr:last-child > td {
        border-bottom: 1px solid $datatables-border-color;
    }

    tbody > tr:nth-child(odd) > td {
        background: $datatables-row-alternate-bgcolor;
    }

    /* waiting >= 24 */
    tbody > tr.Jobmonitor__Pending--Warning > td {
        background: #ffab40;
    }

    tbody > tr.Jobmonitor__Pending--Warning:hover > td {
        background: darken(#ffab40, 10);
    }

    /* waiting >= 36 */
    tbody > tr.Jobmonitor__Pending--Danger > td {
        background: #ef9a9a;
    }

    tbody > tr.Jobmonitor__Pending--Danger:hover > td {
        background: darken(#ef9a9a, 10);
    }

    /* waiting >= 48 */
    tbody > tr.Jobmonitor__Pending--Critical > td {
        background: #ef9a9a;
        animation: pulse 2s ease infinite;
    }

    tbody > tr.Jobmonitor__Pending--Critical:hover > td {
        background: darken(#ef9a9a, 10);
        animation: none;
    }

    tbody > tr.selected,
    tbody > tr > .selected {
        background-color: $brand-primary !important;
    }

    tbody > tr.selected > td,
    tbody > tr.selected:nth-child(odd) > td {
        background: transparent;
    }

    tbody tr.selected a,
    tbody th.selected a,
    tbody td.selected a {
        color: #fff !important;
    }

    &-Controls {
        text-align: center;
    }

    &-Control {
        font-size: 1.286em;
        margin: 0 5px;
    }
}

@keyframes pulse {
    0% {
        background: rgba(#ef9a9a, 1);
    }
    50% {
        background: rgba(#ef9a9a, 0);
    }
    100% {
        background: rgba(#ef9a9a, 1);
    }
}

@keyframes processing-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
