.Contingents_Planner {
    &-Periods {
        background: $contingents-head-bgcolor;
        height: 46px;
        overflow: hidden;
        border-top-left-radius: $border-radius-base;
        border-top-right-radius: $border-radius-base;

        &:before,
        &:after {
            content: "";
            display: table;
        }

        &:after {
            clear: both;
        }
    }

    &-Periods-Next,
    &-Periods-Previous {
        display: block;
        font-size: 2em;
        height: 46px;
        width: 40px;
        color: $contingents-head-text-color;

        &:hover {
            background: $contingents-head-hover-bgcolor;
            color: $contingents-head-hover-text-color;
        }
    }

    &-Periods-Next {
        float: right;
    }

    &-Periods-Previous {
        float: left;
    }

    &-Periods-Dates {
        float: left;
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 1.143em;
        font-weight: bold;
        height: 46px;
        width: calc(100% - (40px * 2));

        &:before,
        &:after {
            content: "";
            display: table;
        }

        &:after {
            clear: both;
        }
    }

    &-Periods-Date {
        display: block;
        float: left;
        width: 20%;
        text-align: center;

        > a {
            color: $contingents-head-text-color;
            line-height: 2.9em;
            display: block;

            &:hover {
                background: $contingents-head-hover-bgcolor;
                color: $contingents-head-hover-text-color;
            }
        }

        &--selected {
            > a {
                background: $contingents-head-hover-bgcolor;
                color: $contingents-head-hover-text-color;
            }
        }
    }

    &-Head {
        padding: 40px 30px;
    }

    &-Title {
        margin: 0 0 5px 0;
        font-size: 1.5em;
        font-weight: bold;
    }

    &-Updated {
        font-size: 0.857em;

        > span {
            font-weight: bold;
        }
    }

    &-Body {

    }

    &-Days {
        width: 100%;

        td,
        th {
            border-top: 1px solid $contingents-border-color;
            padding: 20px;
            text-align: center;
        }

        th {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        tbody > tr:nth-child(odd) > td,
        tfoot > tr > td {
            background: $contingents-row-bgcolor;
        }

        tbody > tr:nth-child(even) > td {
            background: $contingents-row-alternate-bgcolor;
        }

        thead > tr > td:first-child,
        tbody > tr > td:first-child,
        tfoot > tr > td:first-child {
            text-align: left;
        }
    }

    &-Days-Row {
        &--spacer {
            > td {
                background: transparent !important;
            }
        }
    }

    &__Input {
        width: 50px;
        margin: 0 auto;
        text-align: center;
        font-size: 14px;
        box-shadow: none;
        border: 1px solid #ccc;
        background: #fff;
        transition: background 0.15s ease-in-out,
                    border-color 0.15s ease-in-out;

        &:focus {
        }
    }

    &__Controls {
        padding: 20px;
    }
}
