$forum-internal-color: #fff;
$forum-internal-background: rgba(70, 60, 138, 1);

$forum-board-background: #fff;
$forum-board-border: 1px solid #ccc;
$forum-board-avatar-size: 60px;
$forum-board-avatar-font-size: 1.5em;
$forum-board-avatar-color: #000;
$forum-board-avatar-background: #ccc;
$forum-board-avatar-border: 1px solid #000;
$forum-board-internal-color: $forum-internal-color;
$forum-board-internal-background: $forum-internal-background;
$forum-board-sortable-ghost-background: #ccc;

$forum-thread-border: 1px solid #ccc;
$forum-thread-internal-color: $forum-internal-color;
$forum-thread-internal-background: $forum-internal-background;
$forum-thread-detail-background: #ccc;

$forum-post-border-bottom: 1px solid #ccc;
$forum-post-internal-color: $forum-internal-color;
$forum-post-internal-background: $forum-internal-background;

/**
 * --------------------------------------------------
 * 1.   BREADCRUMB
 * 2.   SEARCH
 * 3.   BOARDS
 * 4.   BOARD
 * 4.1. BOARD-AVATAR
 * 4.2. BOARD-INFO
 * 4.3. BOARD-TITLE
 * 4.4. BOARD-DESCRIPTION
 * 4.5. BOARD-THREADS
 * 4.6. BOARD-POSTS
 * 4.7. BOARD-LATEST
 * 4.8. BOARD-DETAIL
 * 5.   THREADS
 * 6.   THREAD
 * 7.   POSTS
 * 8.   POST
 * 9.   SUBSCRIPTIONS
 * --------------------------------------------------
 */
.Forum {
  position: relative;

  h2 {
    margin: 0;
    font-size: 2em;
  }

  /**
   * 1. BREADCRUMB
   */
  &-Breadcrumb {
    position: relative;
    margin-bottom: 25px;

    &-Prefix {
      font-weight: bold;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &:before {
          content: "» ";
          margin-left: 10px;
        }

        &:first-child:before {
          margin-left: 0;
          content: "";
        }

        @media screen and (min-width: 800px) {
          display: inline-block;

          &:before {
            content: "";
            margin-left: 0;
          }

          &:after {
            content: "»";
            margin-left: 4px;
          }

          &:nth-child(1),
          &:last-child {
            &:after {
              content: "";
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  /**
   * 2. SEARCH
   */
  &-Search {
    position: relative;
    margin: 15px 0;
    background: red;
  }

  /**
   * 3. BOARDS
   */
  &-Boards {
    position: relative;
    margin-top: 15px;
  }

  /**
   * 4. BOARD
   */
  &-Board {
    position: relative;
    padding: 5px;
    margin-bottom: 15px;
    border: $forum-board-border;
    background-color: $forum-board-background;

    &--Internal {
      color: $forum-board-internal-color;
      background-color: $forum-board-internal-background;
    }

    &-Sortable-Ghost {
      background-color: $forum-board-sortable-ghost-background;
    }

    &-NewThread {
      margin: 2rem 0 20px 0;
    }

    /**
     * 4.1. BOARD-AVATAR
     */
    &-Avatar {
      display: table-cell;
      padding: 10px;
      min-width: $forum-board-avatar-size;
      text-align: center;
      
      @media screen and (max-width: 767px) {
        display: none;
      }

      &-Circle {
        display: inline-block;
        width: $forum-board-avatar-size;
        height: $forum-board-avatar-size;
        text-align: center;
        font-size: $forum-board-avatar-font-size;
        line-height: $forum-board-avatar-size;
        border-radius: $forum-board-avatar-size;
        color: $forum-board-avatar-color;
        background: $forum-board-avatar-background;
        border: $forum-board-avatar-border;
      }
    }

    /**
     * 4.2. BOARD-INFO
     */
    &-Info {
      display: table-cell;
      padding: 15px;
      width: 100%;
      vertical-align: top;
    }

    &-Manage-Controls {
      display: table-cell;
      padding: 15px;
      vertical-align: middle;
      border-left: $forum-board-border;
      min-width: 145px;
      text-align: center;

      @media screen and (max-width: 767px) { 
        display: flex;
        justify-content: space-around;
        border-left: none;
        border-top: 1px solid #ccc;
      }

      .fa {
        font-size: 1.4em;
      }
    }

    &-Drag-Handle {
      cursor: grab;
      cursor: -webkit-grab;
    }

    /**
     * 4.3. BOARD-TITLE
     */
    &-Title {
      margin: 0 0 5px 0;
      font-size: 1.5em;

      > a {
        // color: inherit;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    /**
     * 4.4. BOARD-DESCRIPTION
     */
    &-Description {
      margin-bottom: 10px;
    }

    /**
     * 4.5. BOARD-THREADS
     */
    &-Threads {
      float: left;
      margin-right: 25px;
      font-weight: bold;
    }

    /**
     * 4.6. BOARD-POSTS
     */
    &-Posts {
      float: left;
      font-weight: bold;
    }

    /**
     * 4.7. BOARD-LATEST
     */
    &-Latest {
      float: right;
    }

    /**
     * 4.8. BOARD-DETAIL
     */
    &-Detail {
      margin-top: 1rem;

      .Forum-Board-Description {
        margin: 10px 0;
        font-size: 1.2em;
        margin-top: 3rem;
      }
    }
  }

  /**
   * 5. THREADS
   */
  &-Threads {
    //
  }

  /**
   * 6. THREAD
   */
  &-Thread {
    position: relative;
    margin-bottom: 15px;
    border: $forum-thread-border;

    &--Internal {
      color: $forum-thread-internal-color;
      background-color: $forum-thread-internal-background;
    }

    &-New {
      position: absolute;
      height: 30px;
      width: 30px;
      border-radius: 15px;
      top: -15px;
      right: 0px;
      line-height: 30px;
      text-align: center;
      color: #fff;
      background: red;
      font-size: 0.8em;
    }

    &-Form {
      margin-top: 20px;
    }

    &-Tools {
      position: relative;
      margin-bottom: 20px;

      button {
        margin: 0;
        padding: 0;
        border: 0;
        background: transparent;
        cursor: pointer;
      }

      button,
      a {
        color: $link-color;
        text-decoration: none;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          display: inline-block;
          margin-right: 25px;
          padding: 0;

          a {
            //
          }
        }
      }
    }

    &-Sticky {
      position: absolute;
      top: 3px;
      left: 3px;
      transform: rotate(30deg);
    }

    &-Icon {
      display: table-cell;
      min-width: 100px;
      padding: 10px 0;
      text-align: center;
      font-size: 2.5em;

      button {
        background: none;
        border: none;
      }
    }

    &-Info {
      display: table-cell;
      width: 100%;
      padding: 15px 0;
      vertical-align: top;
    }

    &-Title {
      margin: 0 0 10px 0;
      font-size: 1.5em;

      > a {
        // color: inherit;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &-Author,
    &-Latest,
    &-Period {
      line-height: 2em;
    }

    &-Author {
      //
    }

    &-Latest {
      //
    }

    &-Period {
      //
    }

    &-Body {
      margin-top: 15px;
      img{
        max-width: 100%;
      }
    }

    &-Actions {
      margin: 25px 0 10px 0;
    }

    &-Additional {
      display: table-cell;
      padding: 15px 15px 0 0;
      min-width: 90px;
      vertical-align: top;

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          display: block;
          margin: 0;
          padding: 0;
          line-height: 30px;

          .fa {
            margin-right: 5px;
            font-size: 1.2em;
          }
        }
      }
    }

    &-Detail {
      position: relative;
      margin-top: 20px;
      padding: 10px;
      background-color: $forum-thread-detail-background;
    }
  }

  /**
   * 7. POSTS
   */
  &-Posts {
    margin-top: 25px;
  }

  /**
   * 8. POST
   */
  &-Post {
    position: relative;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: $forum-post-border-bottom;

    &--Internal {
      color: $forum-post-internal-color;
      background-color: $forum-post-internal-background;
    }

    &-Form {
      margin-top: 20px;
    }

    &-User {
      display: table-cell;
      min-width: 150px;
      padding: 5px;
      vertical-align: top;

      &-Posts {
        margin-top: 10px;
      }
    }

    &-Info {
      display: table-cell;
      width: 100%;
      padding: 5px;
    }

    &-Author {
      display: inline-block;
      font-weight: bold;
      font-size: 1.2em;
    }

    &-Created {
      display: inline-block;
      font-size: 1.2em;
    }

    &-Body {
      margin-top: 15px;
      vertical-align: top;
      img{
        max-width: 100%;
      }
      blockquote {
        padding: 5px 10px;
        font-size: inherit;
        border-left: 5px solid #c7c7c7;
      }
    }

    &-Detail {
      .Forum-Post-Title {
        //
      }

      .Forum-Post-Author {
        line-height: 2em;
        font-size: inherit;
        font-weight: inherit;
      }

      .Forum-Post-Body {
        margin-top: 15px;
      }

      .Forum-Post-Actions {
        //
      }

    }

    &-Actions {
      margin: 10px 0 0px 0;
    }
  }

  /**
   * 9. SUBSCRIPTIONS
   */
  &-Subscriptions {
    margin-top: 20px;
  }
}

.Subboards{
  cursor: pointer;
}

.Subboards > h4 {
  color: #337ab7;
  text-decoration: none;
}