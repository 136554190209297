.Documents {
    .dataTables_filter > select,
    .dataTables_filter > label {
        float: right;
    }

    .dataTables_filter > select {
        margin-right: 20px;
        min-width: 250px;
    }
}

#document-upload {
    .dz-image {
        background: linear-gradient(to bottom,  #e56467 0%, #e6181c 100%);
        border-radius: 8px;
        height: 150px;
    }

    .dz-filename {
        &:after {
            font-family: "Font Awesome 5 Pro"; 
            content: "\f016";
            display: block;
            margin-top: 0.7em;
            font-size: 2.3em;
            padding-bottom: 5px;
            color: #fff;
        }

        &--archive:after {
            content: "\f1c6";
        }

        &--audio:after {
            content: "\f1c7";
        }

        &--doc:after {
            content: "\f1c2";
        }

        &--image:after {
            content: "\f1c5";
        }

        &--pdf:after {
            content: "\f1c1";
        }

        &--presentation:after {
            content: "\f1c4";
        }

        &--spreadsheet:after {
            content: "\f1c3";
        }

        &--text:after {
            content: "\f0f6";
        }

        &--video:after {
            content: "\f1c8";
        }
    }
}
