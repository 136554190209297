//
// Base classes
//

.table-heading {
  position: relative;
  min-height: 60px;
  background: rgb(249, 249, 249);

  &::before, &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    background: inherit;
  }

  &::before {
    left: -20px;
    width: 20px;
  }

  &::after {
    right: -10px;
    width: 10px;
  }

  &--main {
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding: 10px;

    &::before {
      content: none
    }

    &::after {
      right: -30px;
      width: 30px;
    }

    h3 {
      margin: 0;

      @media (max-width: 767.98px) {
        font-size: 14px;
      }
    }
  }
}

.column-item {
  display: block;
  padding: 10px 0;
  background: rgb(255, 255, 255);
}

.horizontal-item {
  display: flex;
  align-items: center;
  padding: 10px;
  background: rgb(255, 255, 255);
  min-height: 105px;

  &--question, &--answer {
    position: relative;

    &:nth-child(2n+1) {
      background: rgb(249, 249, 249);
    }

    &::before, &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      height: 100%;
      background: inherit;
    }
  }

  &--question {
    padding-right: 0;

    &::before {
      content: none
    }

    &::after {
      right: -30px;
      width: 30px;
    }
  }

  &--answer {
    &::before {
      left: -10px;
      width: 10px;
    }

    &::after {
      right: -10px;
      width: 10px;
    }
  }

  h4 {
    margin: 0;

    @media (max-width: 767.98px) {
      font-size: 14px;
    }
  }
}

//
// OwlCarousel2 classes
//

.owl-carousel--custom {
  position: relative;

  .owl-item {
    background: rgb(249, 249, 249);
  }

  .owl-nav {
    position: absolute;
    top: 28px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    width: 80%;
    font-size: 13px;
    color: rgb(111, 111, 111);
    pointer-events: none;

    @media(min-width: 768px) {
      width: 92%;
      font-size: 15px;
    }
    @media(min-width: 1200px) {
      width: 97%;
      font-size: 16px;
    }
  }

  .owl-prev, .owl-next {
    pointer-events: all;
  }
}

//
// Utility classes
//

.flex-column {
  display: flex;
  flex-direction: column;
}

.row.row-normalize {
  margin-right: -15px;
  margin-left: -15px;
}

.w-100 {
  width: 100%;
}

.main-content.display-table-to-block {
  @media (min-width: 768px) {
    display: block;
  }
}