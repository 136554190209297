.Dropzone {
    min-height: 100px;
    border: 2px dashed #ccc;
    border-radius: $border-radius-base;
    margin-bottom: 10px;
    transition: border-color 0.2s ease;
    position: relative;

    &:after {
        position: relative;
        content: attr(data-text);
        display: block;
        text-align: center;
        top: 35px;
        color: #ccc;
        transition: color 0.2s ease;
    }

    &.dz-clickable {
        cursor: pointer;
    }

    &.dz-drag-hover {
        border-color: $brand-primary;

        &:after {
            content: attr(data-text-hover);
        }
    }

    &.dz-started {
        &:after {
            content: "";
        }
    }

    &__Label {
        text-align: center;
        display: block;
        width: 100%;
        margin-top: 35px;
        color: #ccc;
        transition: color 0.2s ease;

        &:after {
            content: attr(data-text);
        }
    }

    &.dz-drag-hover &__Label {
        color: $brand-primary;

        &:after {
            content: attr(data-text-hover);
        }
    }

    &.dz-started &__Label {
        display: none;
    }

    .dz-preview {
        position: relative;
        display: inline-block;
        vertical-align: top;
        margin: 16px;
        min-height: 100px;
    }

    .dz-preview:hover {
        z-index: 1000;
    }

    .dz-preview:hover .dz-details {
        opacity: 1;
    }

    .dz-preview.dz-file-preview .dz-image {
        border-radius: 20px;
        background: #999;
        background: linear-gradient(to bottom, #eee, #ddd);
    }

    .dz-preview.dz-file-preview .dz-details {
        opacity: 1;
    }

    .dz-preview.dz-image-preview {
        background: white;
    }

    .dz-preview.dz-image-preview .dz-details {
        -webkit-transition: opacity 0.2s linear;
        -moz-transition: opacity 0.2s linear;
        -ms-transition: opacity 0.2s linear;
        -o-transition: opacity 0.2s linear;
        transition: opacity 0.2s linear;
    }

    .dz-preview .dz-remove {
        font-size: 14px;
        text-align: center;
        display: block;
        cursor: pointer;
        border: none;
    }

    .dz-preview .dz-remove:hover {
        text-decoration: underline;
    }

    .dz-preview:hover .dz-details {
        opacity: 1;
    }

    .dz-preview .dz-details {
        z-index: 20;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        font-size: 13px;
        min-width: 100%;
        max-width: 100%;
        padding: 2em 1em;
        text-align: center;
        color: rgba(0, 0, 0, 0.9);
        line-height: 150%;
    }

    .dz-preview .dz-details .dz-size {
        margin-bottom: 1em;
        font-size: 16px;
    }

    .dz-preview .dz-details .dz-filename {
        white-space: nowrap;
    }

    .dz-preview .dz-details .dz-filename:hover span {
        border: 1px solid rgba(200, 200, 200, 0.8);
        background-color: rgba(255, 255, 255, 0.8);
    }

    .dz-preview .dz-details .dz-filename:not(:hover) {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .dz-preview .dz-details .dz-filename:not(:hover) span {
        border: 1px solid transparent;
    }

    .dz-preview .dz-details .dz-filename span, .dz-preview .dz-details .dz-size span {
        background-color: rgba(255, 255, 255, 0.4);
        padding: 0 0.4em;
        border-radius: 3px;
    }

    .dz-preview:hover .dz-image img {
        -webkit-transform: scale(1.05, 1.05);
        -moz-transform: scale(1.05, 1.05);
        -ms-transform: scale(1.05, 1.05);
        -o-transform: scale(1.05, 1.05);
        transform: scale(1.05, 1.05);
        -webkit-filter: blur(8px);
        filter: blur(8px);
    }

    .dz-preview .dz-image {
        border-radius: 20px;
        overflow: hidden;
        width: 120px;
        height: 120px;
        position: relative;
        display: block;
        z-index: 10;
    }

    .dz-preview .dz-image img {
        display: block;
    }

    .dz-preview.dz-success .dz-success-mark {
        -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
        -moz-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
        -ms-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
        -o-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
        animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
    }

    .dz-preview.dz-error .dz-error-mark {
        opacity: 1;
        -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
        -moz-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
        -ms-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
        -o-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
        animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
    }

    .dz-preview .dz-success-mark, .dz-preview .dz-error-mark {
        pointer-events: none;
        opacity: 0;
        z-index: 500;
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        margin-left: -27px;
        margin-top: -27px;
    }

    .dz-preview .dz-success-mark svg, .dz-preview .dz-error-mark svg {
        display: block;
        width: 54px;
        height: 54px;
    }

    .dz-preview.dz-processing .dz-progress {
        opacity: 1;
        -webkit-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        -ms-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        transition: all 0.2s linear;
    }

    .dz-preview.dz-complete .dz-progress {
        opacity: 0;
        -webkit-transition: opacity 0.4s ease-in;
        -moz-transition: opacity 0.4s ease-in;
        -ms-transition: opacity 0.4s ease-in;
        -o-transition: opacity 0.4s ease-in;
        transition: opacity 0.4s ease-in;
    }

    .dz-preview:not(.dz-processing) .dz-progress {
        -webkit-animation: pulse 6s ease infinite;
        -moz-animation: pulse 6s ease infinite;
        -ms-animation: pulse 6s ease infinite;
        -o-animation: pulse 6s ease infinite;
        animation: pulse 6s ease infinite;
    }

    .dz-preview .dz-progress {
        opacity: 1;
        z-index: 1000;
        pointer-events: none;
        position: absolute;
        height: 16px;
        left: 50%;
        top: 50%;
        margin-top: -8px;
        width: 80px;
        margin-left: -40px;
        background: rgba(255, 255, 255, 0.9);
        -webkit-transform: scale(1);
        border-radius: 8px;
        overflow: hidden;
    }

    .dz-preview .dz-progress .dz-upload {
        background: #333;
        background: linear-gradient(to bottom, #666, #444);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0;
        -webkit-transition: width 300ms ease-in-out;
        -moz-transition: width 300ms ease-in-out;
        -ms-transition: width 300ms ease-in-out;
        -o-transition: width 300ms ease-in-out;
        transition: width 300ms ease-in-out;
    }

    .dz-preview.dz-error .dz-error-message {
        display: block;
    }

    .dz-preview.dz-error:hover .dz-error-message {
        opacity: 1;
        pointer-events: auto;
    }

    .dz-preview .dz-error-message {
        pointer-events: none;
        z-index: 1000;
        position: absolute;
        display: block;
        display: none;
        opacity: 0;
        -webkit-transition: opacity 0.3s ease;
        -moz-transition: opacity 0.3s ease;
        -ms-transition: opacity 0.3s ease;
        -o-transition: opacity 0.3s ease;
        transition: opacity 0.3s ease;
        border-radius: 8px;
        font-size: 13px;
        top: 130px;
        left: -10px;
        width: 140px;
        background: #be2626;
        background: linear-gradient(to bottom, #be2626, #a92222);
        padding: 0.5em 1.2em;
        color: white;
    }

    .dz-preview .dz-error-message:after {
        content: '';
        position: absolute;
        top: -6px;
        left: 64px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #be2626;
    }

    &--List {
        overflow: hidden;

        .dz-preview {
            margin: 0;
            background: #cfd8dc;
            min-height: initial;
            width: 100%;
            position: relative;
            height: 36px;
            border-bottom: 1px solid #aaa;
            padding: 3px 10px;
            cursor: default;

            &:before,
            &:after {
                content: "";
                display: table;
            }

            &:after {
                clear: both;
            }

            &:last-child {
                border-bottom: 0;
            }

            &.dz-error {
                background: #ffcdd2;
            }

            .dz-file-type {
                float: left;
                width: 30px;
                color: $brand-primary;
                font-size: 1.5em;
                line-height: 1.5em;
                position: relative;
                z-index: 15;
                text-align: center;
            }

            .dz-details {
                float: right;
                width: calc(100% - 30px);
                position: relative;
                top: auto;
                left: auto;
                min-width: auto;
                max-width: none;
                padding: 0;
                line-height: 2.3em;

                &:before,
                &:after {
                    content: "";
                    display: table;
                }

                &:after {
                    clear: both;
                }

                .dz-filename {
                    float: left;

                    span {
                        border: 0;
                        background: transparent;
                        border-radius: 0;
                        font-size: 1.1em;
                    }

                    &:not(:hover) span,
                    &:hover span {
                        border: 0;
                        background: transparent;
                    }
                }

                .dz-size {
                    float: right;
                    margin-bottom: 0;
                    margin-right: 10px;

                    span {
                        background: transparent;
                        border-radius: 0;
                        font-size: 0.8em;
                    }
                }
            }

            .dz-progress {
                opacity: 1;
                left: 0;
                top: 0;
                margin-top: 0;
                margin-left: 0;
                height: 36px;
                width: 100%;
                z-index: 5;
                background: transparent;
                border-radius: 0;

                .dz-upload {
                    background: #81c784;
                }
            }

            .dz-remove {
                z-index: 15;
                font-size: 1.3em;
                line-height: 1.8em;
                float: right;

                &:hover,
                > i.fa:hover {
                    cursor: hover;
                }
            }
        }
    }
}
