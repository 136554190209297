/** Variables and Bootstrap overrides **/
@import "vbst/variables";

/** Bootstrap **/
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

/** Bootstrap class overrides **/
@import "vbst/buttons";
@import "vbst/panels";
@import "vbst/pagination";
@import "vbst/tabs";

/** FontAwesome **/
@import "node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "node_modules/@fortawesome/fontawesome-pro/scss/regular";

/** Nanoscroller **/
@import "vbst/nanoscroller";

/** SweetAlert **/
@import "node_modules/sweetalert/dev/sweetalert.scss";

/** Select2 */
@import "node_modules/select2/src/scss/core";

/** Dropzone */
@import "node_modules/dropzone/src/dropzone";
@import "vbst/dropzone";

/** Perfect Scrollbar **/
@import "vbst/perfectscrollbar";

/** Hive **/
@import "vbst/functions";
@import "vbst/scaffolding";
@import "vbst/template";
@import "vbst/login";
@import "vbst/dashboard";
@import "vbst/datatables";

/** Counselling **/
@import "vbst/counselling";

/** Contingents **/
@import "vbst/contingents";

/** Workfields **/
@import "vbst/workfields";

/** Helpdesk **/
@import "vbst/helpdesk";

/** News **/
@import "vbst/news";

/** Chats **/
@import "vbst/chats";

/** Datepicker **/
@import "vbst/pikaday";

/** Survey **/
@import "embedded/survey";

/** Errors **/
@import "vbst/errors";

/** Documents **/
@import "vbst/documents";

/** Vue Transitions **/
@import "vbst/vue-transitions";

/** Notifications */
@import "embedded/notifications";

/** Forum */
@import "vbst/forum";

/** Tooltips */
@import "node_modules/tether-tooltip/dist/css/tooltip-theme-arrows";
@import "vbst/tooltip";
@import "node_modules/tippy.js/dist/tippy";

/** Redactor Emoticons */
.redactor-dropdown.redactor-dropdown-box-addEmoticon {
    max-width: 255px;

    a {
        display: inline-block;
        font-size: 1.5em;

        &:hover {
            // background-color: #c2c2c2;
        }
    }
}

/** Customized-table for Onlineschlafberatung */
@import "components/customized-table";

.notification-message {
    position: relative;

    &-indicator {
        background-color: $brand-info;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        margin-top: -5px;
        position: absolute;
        top: 50%;
        right: 10px;
    }

    .bold {
        font-weight: bold;
    }
}

.label-history {
    margin-right: 10px;
}

.password-input {
    height: 36px;
}

.position-relative {
    position: relative;
}

.padding-left-35 {
    padding-left: 35px !important;
}

.online-form-button {
    position: absolute;
    left: 5px;
    display: block;
    width: 30px;
    height: 45px;
    font-size: 20px;
    text-align: center;
    z-index: 10;

    .fa {
        line-height: 44px;
    }
}

.p-0{
    padding-left: 0;
    padding-right: 0;
}

p[data-comment]:after {
    font-family: "Font Awesome 5 Pro";
    content: "\f075";
    display: inline-block;
    vertical-align: middle;
    font-weight: 900;
}

p[data-comment] > ins:after  {
    font-family: "Font Awesome 5 Pro";
    content: "\f075";
    padding-left: 3px;
    display: inline-block;
    vertical-align: middle;
    font-weight: 900;
}
