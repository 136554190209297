.Pagination__Container {
    &:before,
    &:after {
        display: table;
        content: "";
    }

    &:after {
        clear: both;
    }
}

.pagination {
    margin-top: 0;
    margin-bottom: 0;

    > li {
        > a,
        > span {
            padding: 14px 24px;
            font-weight: bold;
        }

        &:first-child {
            > a,
            > span {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        &:last-child {
            > a,
            > span {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
}
