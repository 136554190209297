.expand-transition {
    transition: all 0.25s ease;
    max-height: 500px;
    overflow: hidden;
}

.expand-enter,
.expand-leave {
    max-height: 0;
}
