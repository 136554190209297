.dashboard {
    .block {
        margin-bottom: 40px;
        padding: 20px 20px;
        background-color: #fff;
        border-left: 1px solid #efefef;
        border-right: 1px solid #efefef;
        border-bottom: 1px solid #e2e2e2;
        box-shadow: 0 1px 0 0 rgba(0, 0, 0, .04);

        .header {
            padding: 3px 0;
            border-bottom: 1px solid #dadada;

            h3 {
                margin-top: 3px;
                font-weight: 100;
            }
        }

        .content {
            padding: 15px 3px 0 3px;

            h4 {
                margin-top: 20px;
            }
        }
    }

    .widget-block {
        overflow: hidden;
        padding: 0;

        .padding {
            padding: 20px;
        }
    }
}