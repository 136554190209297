.survey-link {
    text-decoration: none;
    color: inherit;
    
    &:hover {
        cursor: pointer;
        color: inherit;
        text-decoration: none;
        background-color: white;
    }
    
    &:focus {
        text-decoration: none;
    }
}

.survey-add-btn {
    padding: 0 10px !important;
}

.survey-header {
    color: #337ab7
}

.survey-item {
    border: 1px solid #ccc;
    padding: 2rem 2rem 2rem 2rem;
    margin-bottom: 2rem;
    
    &:hover {
        background-color: #f8f8f8;
    }
}

.survey-meta {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem; 
    
    div {
        margin-right: 1rem;
    }
}

.divider {
    color: grey;
    font-weight: bold;
}

.margin-bottom {
    margin-bottom: 2rem;
}

.empty-msg {
    color: grey;
    text-align: center;
}

// PAGE EDIT
.page-devider {
    position: relative;
}

.page-element {
    color:#000;
    background:#fff;
    max-width: 70vw;
    overflow-wrap: break-word;
}

.editPage {
    margin-top: 3rem;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 1px 1px 10px darkgrey;
}

.page-input {
    line-height: 42px;
    font-size: 18px;
}

.page-icons {
    position: absolute;
    font-size: 18px;
    top: 0;
    right: 0;
    cursor: pointer;
}

// SECTION EDIT
.section-devider {
    position: relative;
}

.section-element {
    color:#000;
    background:#fff;
    max-width: 70vw;
    overflow-wrap: break-word;
}

.section-icons {
    position: absolute;
    font-size: 18px;
    top: 0;
    right: 0;
    cursor: pointer;
}

.section-input {
    line-height: 42px;
    font-size: 18px;
}

.custom-hr {
    border-color: #b8b8b8;
}


.editSection {
    margin-top: 3rem;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 1px 1px 10px darkgrey;
}

// QUESTION
.question-devider {
    margin-top: 25px;
    width:auto;
    border-top: 1px solid #ebebeb;
    padding: 3rem 0 3rem 0;
    position: relative;
}

.question-icon {
    height:45px;
    font-size:18px;
    color:#000;
    line-height:42px;
    text-align:center;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}

.question-element {
    padding: 0 10px;
    width: 100%;
}

.question-editor .ql-editor {
    height: 75px;
    resize: vertical;
}

.choice-editor .ql-editor {
    height: 25px;
    resize: vertical;
}

.choice-icons {
    cursor: pointer;
}

.d-flex {
    display: flex;
    flex-wrap: nowrap;
    align-items: center ;
}

.suffix {
    padding-left: 2rem; 
    max-width: 180px;
}

.prefix {
    padding-right: 2rem; 
    max-width: 180px;
}

.required {
    color: red;
    padding-left: 3px;
    font-size: large;
    font-weight: bold;
}

.question-meta {
    display: flex;
    font-weight: lighter;
    font-size: small;
    font-style: italic;
    margin-bottom: 10px;
}

.question-meta .star {
    color: red;
    font-weight: bold;
    font-size: larger;
}

.question-meta .divider {
    font-size: larger;
    color: grey;
    margin: 0 1rem 0 1rem;
}

.question-tooltip {
    color: #337ab7;
}

.question-tooltip .tooltiptext {
    visibility: hidden;
    color: black;
    min-width: 120px;
    max-width: 290px;
    background-color: white;
    border-radius: 6px;
    padding: 1rem;
    box-shadow: 0px 0px 10px #bbb;
    border: 1px solid #888;            
    overflow-wrap: break-word;


    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.question-tooltip:hover .tooltiptext {
    visibility: visible;
    min-width: 120px;
}

.editSection {
    margin-top: 3rem;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 1px 1px 10px darkgrey;
}

.edit-box-btn-row {
    display: flex;
    justify-content: center;
}

// CHOICE EDIT
.choice-utils {
    display: flex;
    justify-content: space-between;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 0 1rem;
    background-color: #f5f9ff;
}

.margin-y-3 {
    margin: 0 3rem;
}

.choice-boxes {
    padding: 0 5rem;

    @media(max-width: 767px) {
        padding: 0;
    }
}