@import "node_modules/bulma/sass/utilities/functions.sass";
@import "node_modules/bulma/sass/utilities/variables.sass";
@import "node_modules/bulma/sass/utilities/mixins.sass";
@import "node_modules/bulma/sass/elements/other.sass";
@import "node_modules/bulma/sass/elements/notification.sass";

.fa {
    font-size: inherit;
    text-align: center;
    vertical-align: middle;
}
