.btn--loading {
    > .fa {
        animation: spin 1s infinite linear;

        &:before {
            content: "\f021";
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
