[v-cloak] {
    display: none;
}

html,
body {
    height: 100%;
}

body {
    position: relative;
    height: 100%;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: 400;
}

h1:first-child {
    margin-top: 0;
}

a:focus,
a:hover,
a:active {
    text-decoration: none;
    outline: 0 none;
}

.form-control {
    font-size: 14px;
    padding: 6px 8px;
    border-radius: 1px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05) inset;
}

.input-group {
    margin-bottom: 15px;
}

.input-group-addon {
    border-radius: 1px;
    padding: 6px 11px;
    background-color: #fafafa;
}

.btn {
    border-radius: 6px;
    outline: none;
    padding: 7px 11px;

    &-xs,
    &-group-xs > .btn {
        padding: 1px 5px;
    }
}

.thin {
    font-weight: 100 !important;
}

.spacer {
    margin-top: 15px !important;
}

.Button {
    &--rounded-corners {
        border-radius: $border-radius-base;
    }

    &--full-width {
        width: 100%;
    }
}

.resize-vertical {
    resize: vertical;
}

.tab-content.tab-content-padding-top {
    padding-top: 10px;
}

.tab-pane-permissions {
    .col-md-4 {
        border-bottom: 1px solid #ccc;
        margin-bottom: 20px;
    }
}

.mb-0 {
    margin-bottom: 0;
}

.mb-1 {
    margin-bottom: 1em;
}
