.Helpdesk-Teaser {
    border: 1px dashed $helpdesk-teaser-border-color;
    padding: 25px;

    &-Headline {
        margin-top: 0;
        margin-bottom: 25px;
    }

    &-Items {
        margin: 0;
        padding: 0;
        list-style: none;
        text-align: center;
    }

    &-Item {
        display: inline-block;
        width: 60px;
        height: 40px;
        border: 2px solid $helpdesk-teaser-item-border-color;
        border-radius: $helpdesk-teaser-border-radius;
        text-align: center;

        margin-right: 5px;

        &:last-child {
            margin-right: 0;
        }
    }

    &-Icon {
        display: block;
        background-repeat: no-repeat;
        background-position: center center;

        &--tutorial {
            width: 42px;
            height: 32px;
            background-image: url("/img/helpdesk/icon-tutorial.png");
            background-size: 42px 32px;
            margin: 3px 6px;
        }

        &--manual {
            width: 25px;
            height: 23px;
            background-image: url("/img/helpdesk/icon-book.png");
            background-size: 25px 23px;
            margin: 6px 15px;
        }

        &--contact {
            width: 48px;
            height: 31px;
            background-image: url("/img/helpdesk/icon-mails.png");
            background-size: 48px 31px;
            margin: 3px;
        }
    }

    &-Body {
        margin-top: 20px;
    }

    &-More {
        text-align: right;

        > a {
            font-size: 1.071em;
            font-weight: bold;
        }
    }
}

/**
 * Helpdesk Index
 */

.Helpdesk-index {
    list-style: none;
    margin: 0 0 10px 0;
    padding-left: 0;
    border-left: 5px solid $helpdesk-index-book-color;

    a,
    li {
        display: block;
    }

    li {
        a {
            display: block;
            border-bottom: none;
            padding: 6px 0 6px 12px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &-description {
        padding-left: 12px;
        font-size: 0.9em;
    }

    &-book {
        color: $helpdesk-index-book-color;

        &:hover {
            color: darken($helpdesk-index-book-color, 10%);
        }

        &--selected,
        &:hover {
            background-color: transparentize($helpdesk-index-book-color, 0.9);
        }
    }

    &-book-header {}

    &-chapter {
        color: $helpdesk-index-chapter-color;

        &:hover {
            color: darken($helpdesk-index-chapter-color, 10%);
        }

        &--selected,
        &:hover {
            background-color: transparentize($helpdesk-index-chapter-color, 0.9);
        }
    }

    &-chapter-item {
        display: block;
        margin: 10px;
        border-left: 5px solid $helpdesk-index-chapter-color;
    }

    &-pages {
        list-style: none;
        margin: 0;
        padding-left: 0;
    }

    &-page {
        color: $helpdesk-index-page-color;

        &:hover {
            color: darken($helpdesk-index-page-color, 10%);
        }

        &--selected,
        &:hover {
            background-color: transparentize($helpdesk-index-page-color, 0.9);
        }
    }

    &-page-item {
        border-bottom: none;
        border-left: 5px solid $helpdesk-index-page-color;
        margin: 10px;
    }
}

/**
 * Helpdesk Page
 */

.Helpdesk-Page {
    position: relative;

    .panel-collapsible {
        margin: 0;
        border: 1px solid lightgrey !important;
        border-radius: 0;

        .panel-heading {
            background-color: #efefef;
            border-radius: 0;
        }
        
        .panel-title {        
            a::before {
                font-family: "Font Awesome 5 Pro";
                content: "\f078";
                margin-right: 10px;
                font-size: 16px;
            }
            
            a.collapsed::before {
                font-family: "Font Awesome 5 Pro";
                content: "\f054";
                margin-right: 16px;
                font-size: 16px;
            }
        }
    }


    &-Empty {
        //
    }

    &-Headline {
        margin: 0 0 4rem 0;
        font-weight: bold;
        text-align: center;
    }

    &-Tools {
        position: absolute;
        top: 6px;
        right: 0;
    }

    &-Text {
        line-height: 2.5rem;
    }

    &-Tags {
        float: right;
        position: relative;
        display: table;
        margin: 0 0 10px 10px;
        top: 0;
        right: 0;
        width: 250px;
        z-index: 1;
        border: 1px solid #ddd;

        &-Head {
            display: table-caption;
            width: 100%;
            border: 1px solid #ddd;
            padding: 6px 12px;
            font-weight: bold;
        }

        &-Row {
            display: table-row;

            // &:nth-child(even) {
            //   background-color: #ffe37f;
            // }

            &:nth-child(odd) {
              background-color: #f9f9f9;
            }

            &:last-child .Helpdesk-Page-Tags-Cell {
                border-bottom: none;
            }
        }

        &-Cell {
            display: table-cell;
            border-bottom: 1px solid #ddd;
            padding: 6px 12px;

            &--name {
                font-weight: 400;
            }

            &--value {
                //
            }
        }
    }
}

.Helpdesk-search {
    position: relative;
    margin: 0px 0 20px 0;
    width: 100%;
    height: 30px;

    input {
        position: absolute;
        float: left;
        width: 80%;
        height: 30px;
    }

    .btn {
        position: absolute;
        width: 20%;
        right: 0;
        border-radius: 0;
        height: 30px;
    }
}
