.News_List {
    &-Item {
        padding: 20px;
        border: 1px solid $news-list-border-color;
        position: relative;
        margin: 5px;
        &:nth-child(odd) {
            background: $news-list-odd-bgcolor;
        }

        &:nth-child(even) {
            background: $news-list-even-bgcolor;
        }

        &:before,
        &:after {
            content: "";
            display: table;
        }

        &:after {
            clear: both;
        }
    }

    &-Label {
        display: inline-block;
        background: $news-list-label-bgcolor;
        border-radius: $news-list-label-border-radius;
        color: $news-list-label-text-color;
        font-size: 0.857em;
        padding: 3px 10px;
        position: absolute;
        right: 20px;
        top: 20px;

        &--unread {

        }
    }

    &-Image {
        float: left;
        margin-right: 20px;
    }

    &-Headline {
        font-size: 1.286em;
        font-weight: bold;
        margin-top: 5px;
        margin-bottom: 2px;
    }

    &-Subline {
        font-size: 1.143em;
        margin-top: 3px;
        margin-bottom: 2px;
    }

    &-Properties {
        font-size: 0.857em;
    }

    &-Author {

    }

    &-Date {

    }

    &-Teaser {
        margin-top: 10px;
        font-size: 0.929em;
        font-weight: bold;
        margin-bottom: 0;
    }

    &-Readmore {
        font-size: 0.929em;
        font-weight: bold;
    }

    &-All {
        display: block;
        text-align: center;
        font-size: 1.071em;
        padding: 20px;
        font-weight: bold;

        &:visited {
            color: $link-color;
        }
    }
}

.News {
    &__Headline {
        margin-bottom: 5px;
    }

    &__Subline {
        font-size: 1.143em;
        margin-top: 0;
    }

    &__Body {}

    &__Attachments {
        margin: 20px 0 0 0;
        padding: 0;

        > li {
            margin: 0 0 10px 0;
            padding: 0;
            list-style-type: none;

            &:before,
            &:after {
                content: "";
                display: table;
            }

            &:after {
                clear: both;
            }

            i.fa {
                font-size: 1.3em;
                color: $brand-primary;
            }

            > a {
                float: right;
            }
        }

        &--Listing {
            margin-top: 10px;

            > li {
                > a {
                    float: none;

                    > i.fa {
                        margin-right: 5px;
                    }
                }
            }
        }

        &__Label {
            margin-top: 20px;
            font-weight: bold;
        }
    }
}
